// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/Russ/Dev/bitmoremedia-apps/www-bitmoremedia/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/Russ/Dev/bitmoremedia-apps/www-bitmoremedia/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/Users/Russ/Dev/bitmoremedia-apps/www-bitmoremedia/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/Russ/Dev/bitmoremedia-apps/www-bitmoremedia/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partnerships-js": () => import("/Users/Russ/Dev/bitmoremedia-apps/www-bitmoremedia/src/pages/partnerships.js" /* webpackChunkName: "component---src-pages-partnerships-js" */),
  "component---src-pages-products-js": () => import("/Users/Russ/Dev/bitmoremedia-apps/www-bitmoremedia/src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-services-development-js": () => import("/Users/Russ/Dev/bitmoremedia-apps/www-bitmoremedia/src/pages/services/development.js" /* webpackChunkName: "component---src-pages-services-development-js" */),
  "component---src-pages-services-index-js": () => import("/Users/Russ/Dev/bitmoremedia-apps/www-bitmoremedia/src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-marketing-js": () => import("/Users/Russ/Dev/bitmoremedia-apps/www-bitmoremedia/src/pages/services/marketing.js" /* webpackChunkName: "component---src-pages-services-marketing-js" */),
  "component---src-pages-services-user-experience-js": () => import("/Users/Russ/Dev/bitmoremedia-apps/www-bitmoremedia/src/pages/services/user-experience.js" /* webpackChunkName: "component---src-pages-services-user-experience-js" */)
}

exports.data = () => import("/Users/Russ/Dev/bitmoremedia-apps/www-bitmoremedia/.cache/data.json")

